/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import React, { useEffect, useState } from 'react';
import {
  NiagaraBanner,
  NiagaraButton2,
  NiagaraModal,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { Modal } from '@scuf/common';
import './UsageMetricsModalV2.scss';
import { NiagaraChart } from '../../lazyLoad/NiagaraChartLazy';
import {
  IMetricsDataV2,
  getMetricsData,
  METRICS_DURATION_ANNUALLY,
  METRICS_ID_RETRIEVAL,
  OFFERING_CODE_NDS,
  MetricsDuration,
  METRICS_DURATION_YEARLY,
} from '../../api/usage';
import { RetrievalMetricsBar } from '../../components/RetrievalMetrics/RetrievalMetricsBar';
import { bytesToString } from './metricsUtils';
import { covertByteToGB } from './metricsUtils/unitsToString';
import { Loader } from '../../utils/Loader';
import { DeviceWithProjectIdAndSubscriptions } from '../ProjectStore';
import { SubscriptionInfo } from './SubscriptionInfo';
import { useTagScreen } from '../../utils/localytics/useAnalytics';
import { retrievalMetricsChartOptionsV2 } from './retrievalMetricsChartOptionsV2';
import {
  RETRIEVAL_CHART_LAYOUT,
  RETRIEVAL_USAGE_BASELINE,
  RETRIEVAL_USAGE_LIMIT_EXCEEDED_HEADER,
  RETRIEVAL_USAGE_WARNING_HEADER,
  RETRIEVAL_USAGE_WARNING_LIMT,
  RETRIEVAL_USAGE_WARNING_SUB_HEADER,
} from './constants';
import moment from 'moment';
import Highcharts from 'highcharts';
import { useTheme } from '../../utils/Theme/ThemeProvider';
import _ from 'lodash';

interface IUsageMetricsModal {
  device: DeviceWithProjectIdAndSubscriptions;
  onClose: () => void;
}

export function UsageMetricsModalV2({ onClose, device }: IUsageMetricsModal) {
  const { deviceUuid, subscriptions } = device;
  const [isRetrievalLoading, setIsRetrievalLoading] = useState(false);
  const [retrievalMetricsData, setRetrievalMetricsData] = useState<IMetricsDataV2>();
  const [retrievalDuration, setRetrievalDuration] = useState<MetricsDuration>(METRICS_DURATION_ANNUALLY);
  const [showChart, setShowChart] = useState(false);
  const { theme } = useTheme();

  useEffect(() => {
    if (!subscriptions || subscriptions.length === 0) return;
    const subscription = _.find(subscriptions, { offeringCode: 'NDS' });
    const startDate = moment(subscription?.startDate);
    const today = moment();
    const monthDifference = today.diff(startDate, 'months', true);
    const duration = monthDifference > 13 ? METRICS_DURATION_YEARLY : METRICS_DURATION_ANNUALLY;
    const shouldShowChart = monthDifference > 2;

    setRetrievalDuration(duration);
    setShowChart(shouldShowChart);
    setIsRetrievalLoading(true);
    getMetricsData(deviceUuid, duration, METRICS_ID_RETRIEVAL, OFFERING_CODE_NDS)
      .then(setRetrievalMetricsData)
      .finally(() => setIsRetrievalLoading(false));
  }, [deviceUuid]);

  useTagScreen('Usage Metrics');

  const optionsChart = retrievalMetricsChartOptionsV2(
    retrievalMetricsData?.totalValue,
    retrievalMetricsData?.data ?? [],
    retrievalMetricsData?.summaryValue,
    retrievalDuration,
    theme
  );
  const retrievalUsage = parseInt(covertByteToGB(retrievalMetricsData?.summaryValue ?? 0));
  const retrievalLimit = parseInt(covertByteToGB(retrievalMetricsData?.totalValue ?? 0));
  const retrievalUsagePercentage = (retrievalUsage / retrievalLimit) * 100;
  const header =
    retrievalUsagePercentage >= RETRIEVAL_USAGE_WARNING_LIMT && retrievalUsagePercentage <= RETRIEVAL_USAGE_BASELINE
      ? RETRIEVAL_USAGE_WARNING_HEADER
      : RETRIEVAL_USAGE_LIMIT_EXCEEDED_HEADER;
  const exceededLimit = (retrievalUsage - retrievalLimit).toFixed(0);
  const subHeader =
    retrievalUsagePercentage >= RETRIEVAL_USAGE_WARNING_LIMT && retrievalUsagePercentage <= RETRIEVAL_USAGE_BASELINE ? (
      RETRIEVAL_USAGE_WARNING_SUB_HEADER
    ) : (
      <>
        Your usage has exceeded the plan limit by <strong>{exceededLimit} GB</strong>
      </>
    );
  return (
    <NiagaraModal className='usage-metrics-modalV2' open={true} size='large'>
      <div className='scrollable-modal'>
        <Modal.Header>
          <div className='modal-heading'>Usage Metrics</div>
          <NiagaraButton2 type='secondary-link' icon='Close' iconColor='#606060' onClick={onClose} />
        </Modal.Header>
        <Modal.Content>
          {!showChart && retrievalUsagePercentage < RETRIEVAL_USAGE_WARNING_LIMT && (
            <NiagaraBanner
              subheader='Due to the short subscription duration (less than two months), we could not generate metrics.'
              type='Info'
            />
          )}
          {retrievalUsagePercentage > RETRIEVAL_USAGE_WARNING_LIMT && (
            <NiagaraBanner header={header} subheader={subHeader} type={`Alert`} />
          )}
          <div className='scroll-window'>
            <SubscriptionInfo subscriptions={subscriptions} />
            <div className='retrieval-metrics'>
              {isRetrievalLoading ? (
                <Loader className='usage-metrics-loader' />
              ) : (
                <>
                  <RetrievalMetricsBar
                    usage={retrievalUsage}
                    unitStr={bytesToString(retrievalMetricsData?.summaryValue, true)}
                    usageLimit={retrievalLimit}
                    usageLimitStr={bytesToString(retrievalMetricsData?.totalValue, true, 0)}
                    colors={
                      theme === 'dark'
                        ? ['#68D560', '#FF9847', '#FF644C', '#272727']
                        : ['#159D62', '#D1670B', '#EE3124', '#F4F4F4']
                    }
                  />
                  {showChart && retrievalMetricsData?.summaryValue > 0 && (
                    <div className='retrieval-chart'>
                      <NiagaraChart containerProps={RETRIEVAL_CHART_LAYOUT} options={{ ...optionsChart }} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal.Content>
      </div>
    </NiagaraModal>
  );
}
